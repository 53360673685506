import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'

import { H1, LargeP } from 'src/components/shared/text'
import Spinner from 'src/components/shared/Spinner'
import PurchaseCard from './PurchaseCard'
import { trackProductViewEvent } from '../../../utils/event-tracking'
import StoreContext from '../../../context/StoreContext'

import { media } from '../../../styles/util'

const Section = styled.div`
  width: 100%;

  display: grid;
  place-items: center;

  ${media.mobile} {
    grid-row-gap: 40px;
  }
  ${media.tablet} {
    margin-top: 60px;
    grid-row-gap: 62px;
  }

  & h1 {
    padding: 0 20px;
  }
`

const GradientWrapper = styled.div`
  position: relative;

  width: 100%;
  min-height: 500px;

  display: grid;
  place-items: center;

  ${media.mobile} {
    padding: 0 10px;
  }
  ${media.tablet} {
    padding: 0 20px;

    background: linear-gradient(
      180deg,
      rgba(250, 102, 102, 0) 19.76%,
      rgba(250, 102, 102, 0.19) 40.32%,
      rgba(164, 87, 138, 0.13) 54.55%,
      rgba(164, 87, 138, 0) 81.33%
    );
  }
`

const Grid = styled('div')<{ colCount: number }>`
  width: 100%;

  display: grid;
  place-items: center;

  grid-gap: 24px;

  ${media.tablet} {
    grid-gap: 57px;
    grid-template-columns: repeat(2, 1fr);
    max-width: 650px;
  }
  ${media.desktop} {
    grid-gap: 57px;
    grid-template-columns: ${props => (props.colCount === 3 ? '1fr 1.2fr 1fr' : 'repeat(2, 1fr)')};
    max-width: ${props => (props.colCount === 3 ? '1100px' : '800px')};
  }
`

const OutOfStockCopy = styled(LargeP)`
  color: ${props => props.theme.colors.orange};
  margin-top: 30px;
`

const Purchase = ({
  data,
  otpOptions,
  isOutOfStock,
}: {
  data: any
  otpOptions: string[]
  isOutOfStock: boolean
}) => {
  const { loading, store, products, addVariantToCart, setCartMenuOpen } = useContext(StoreContext)

  const [revealCards, setRevealCards] = useState(false) // For product selectors

  const productSku = data.dataJson.sku
  const productDetail = products?.byProduct[productSku]

  useEffect(() => {
    if (!loading && productDetail) {
      setRevealCards(true)

      const { slug, shortTitle } = productDetail?.jsonData

      const allProductVariantIds: string[] = []
      Object.keys((variantKey: string) => {
        const { variantId } = productDetail[variantKey]
        allProductVariantIds.push(variantId)
      })

      trackProductViewEvent(
        shortTitle.toLowerCase(),
        slug,
        allProductVariantIds,
        productDetail?.single?.price
      )
    }
  }, [loading, productDetail])

  let smartrrSellingPlan = ''
  if (productDetail) {
    smartrrSellingPlan = productDetail?.single?.pricing?.subscription
  }

  const handleAddToCart = async (variantId: string, oneTime: boolean) => {
    if (store && store.cart) {
      const isSubscriptionPurchase = !oneTime && smartrrSellingPlan
      await addVariantToCart(variantId, 1, isSubscriptionPurchase ? smartrrSellingPlan : null)
      setCartMenuOpen(true)
    }
  }

  const cardCount = otpOptions.length + (smartrrSellingPlan ? 1 : 0)

  return (
    <Section>
      <div>
        <H1>Get your Grummies, your way</H1>
        {isOutOfStock && (
          <OutOfStockCopy>
            <b>Sold out!</b>
          </OutOfStockCopy>
        )}
      </div>
      <GradientWrapper>
        {revealCards ? (
          <Grid colCount={cardCount}>
            {smartrrSellingPlan && (
              <PurchaseCard
                type="single"
                cardCount={cardCount}
                productDetail={productDetail}
                isSubscription
                addToCart={handleAddToCart}
                idx={0}
                isOutOfStock={isOutOfStock}
              />
            )}

            {otpOptions.map((type: string, idx: number) => (
              <PurchaseCard
                key={type}
                type={type}
                cardCount={cardCount}
                productDetail={productDetail}
                isSubscription={false}
                addToCart={handleAddToCart}
                idx={idx + 1}
                isOutOfStock={isOutOfStock}
              />
            ))}
          </Grid>
        ) : (
          <Spinner color={props => props.theme.colors.purple} />
        )}
      </GradientWrapper>
    </Section>
  )
}

export default Purchase

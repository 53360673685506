import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Pagination from '@material-ui/lab/Pagination'
import Img from 'gatsby-image'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import DropdownMenu from '../shared/DropdownMenu'
import FilterTag from '../shared/FilterTag'
import { P } from '../shared/text'
import { media } from '../../styles/util'

import HeaderImage from '../../assets/img/text-headers/real-reviews.svg'
// import VerifiedPurchaseBadge from '../../assets/img/icons/verified-purchase-badge.svg'
import PurpleStar from '../../assets/img/icons/purple-star.svg'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & button {
    color: ${props => props.theme.colors.purple};
  }

  ${media.mobile} {
    padding: 20px 20px 0 20px;
  }
  ${media.tablet} {
    padding: 60px 20px 0 20px;
  }
`

const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 80px;

  ${media.mobile} {
    max-width: 210px;
  }
  ${media.tablet} {
    max-width: 410px;
  }
`

const BottleImageWrapper = styled.div`
  position: absolute;
  transform: translate(-50%, 0);
  bottom: 0;
  left: 50%;
  width: 100%;

  ${media.mobile} {
    height: 100px;
  }
  ${media.tablet} {
    height: 130px;
  }
`

const Grid = styled.div`
  height: fit-content;
  width: 100%;
  display: grid;
  grid-row-gap: 29px;

  ${media.mobile} {
    margin: 26px auto 18px auto;
  }
  ${media.tablet} {
    max-width: 600px;
    margin: 50px auto 18px auto;
  }
  ${media.desktop} {
    max-width: 712px;
    margin: 50px auto 18px auto;
  }
`

const GradientWrapper = styled.div`
  position: relative;
  background: linear-gradient(
    90deg,
    #fa6666 11.69%,
    #f090c3 28.66%,
    #4bd1c7 62.14%,
    #21a0f8 91.84%
  );
  padding: 5px;
  border-radius: 10px;
  min-height: 120px;

  ${media.mobile} {
    width: 95%;
    margin: 0 14px 0 9px;
  }
  ${media.tablet} {
    width: 100%;
    margin: 0;
  }
`

const StarGrid = styled('div')<{ cols: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.cols}, 16px);
  grid-column-gap: 6px;
  margin-right: 17px;
`

const QuoteContainer = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 6px;

  ${media.mobile} {
    padding: 19px 28px 22px 13px;
  }
  ${media.tablet} {
    padding: 25px 45px 24px 21px;
  }
`

const QuoteP = styled(P)`
  ${media.mobile} {
    text-align: left;
    font-size: 11px;
    line-height: 14px;
  }
  ${media.tablet} {
    font-size: 16px;
    line-height: 20px;
    max-width: 600px;
  }
`

// const VerifiedPurchaseImg = styled.img`
//   height: auto;
//   position: absolute;
//   transform: translate(50%, -50%);
//   top: 50%;
//   right: 0;

//   ${media.mobile} {
//     width: 45px;
//   }
//   ${media.tablet} {
//     width: 81px;
//   }
// `

const HiddenMobileP = styled(P)`
  ${media.mobile} {
    display: none;
  }
  ${media.tablet} {
    display: block;
  }
`

const HeaderImg = styled.img`
  width: 100%;
`

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  font-family: Cooper, serif;
  text-transform: capitalize;
`

const MobileFlexRow = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;

  ${media.tablet} {
    display: none;
  }
`

const SpreadFlexRow = styled(FlexRow)`
  ${media.mobile} {
    margin-bottom: 11px;
    flex-direction: column;
    align-items: flex-start;
  }
  ${media.tablet} {
    margin-bottom: 24px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

const DropdownRow = styled.div`
  display: grid;
  grid-row-gap: 16px;

  ${media.tablet} {
    grid-template-columns: auto 1fr;
  }
`

const TagsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  ${media.mobile} {
    margin: 0 7px;
  }
  ${media.tablet} {
    margin: 0;
  }
`

interface JunipCustomer {
  id: number
  created_at: string
  first_name: string
  last_name: string
  updated_at: string
}

interface JunipProductReview {
  id: number
  body: string
  created_at: string
  updated_at: string
  customer: JunipCustomer
  rating_count: number
  rating_average: number
  customer_id: number
  down_vote_count: number
  featured: boolean
  photo_urls: { large: string; thumbnail: string }[]
  product_id: number
  rating: number
  response: string
  title: string
  verified_buyer: boolean
}

interface ReviewsInterface {
  bottleImage: any
  reviews: JunipProductReview[]
  reviewFilters: any
  reviewProducts: any
  filterProduct: any
}

const Reviews = ({
  bottleImage,
  reviews,
  reviewFilters,
  reviewProducts,
  filterProduct,
}: ReviewsInterface) => {
  const [page, setPage] = useState(1)
  const visible = false // TODO: reviews.length > 50

  useEffect(() => {
    if (visible) {
      gsap.registerPlugin(ScrollTrigger)

      gsap.from('.pdp__reviews--gradient-wrapper', {
        scrollTrigger: '.pdp__reviews--gradient-wrapper',
        x: -100,
        ease: 'ease-in-out',
        opacity: 0,
        duration: 1,
        stagger: 0.25,
      })
    }
  }, [page, visible])

  const reviewsPerPage = 4
  const pageCount = Math.ceil(reviews.length / reviewsPerPage)
  const beginningReviewIdx = page * reviewsPerPage - reviewsPerPage
  const endingReviewIdx = beginningReviewIdx + reviewsPerPage

  const handleChange = (_: any, value: number) => {
    setPage(value)
  }

  const options = Object.keys(reviewProducts).map(productId => ({
    value: productId,
    label: reviewProducts[productId],
  }))

  return visible ? (
    <Container id="reviews">
      <HeaderWrapper>
        <HeaderImg src={HeaderImage} alt="Real Reviews" />
        <BottleImageWrapper>
          <Img
            fluid={bottleImage}
            alt="Bottle image"
            style={{ width: '100%', height: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </BottleImageWrapper>
      </HeaderWrapper>

      <Grid>
        <DropdownRow>
          <DropdownMenu
            label="Filter by product"
            options={options}
            filterProduct={filterProduct}
            reviewFilters={reviewFilters}
          />
          <TagsContainer>
            {Object.keys(reviewFilters).map(productId => (
              <FilterTag
                key={productId}
                text={reviewFilters[productId]}
                id={productId}
                clickHandler={filterProduct}
              />
            ))}
          </TagsContainer>
        </DropdownRow>

        {reviews.slice(beginningReviewIdx, endingReviewIdx).map(review => {
          let i = review.rating
          const stars = []
          while (i > 0) {
            stars.push(i)
            i -= 1
          }

          const customerName = `${review.customer.first_name} ${
            review.customer.last_name ? `${review.customer.last_name.slice(0, 1)}.` : ''
          }`

          const date = new Date(review.created_at)

          return (
            <GradientWrapper key={customerName} className="pdp__reviews--gradient-wrapper">
              <QuoteContainer>
                {/* {review.verified_buyer && <VerifiedPurchaseImg src={VerifiedPurchaseBadge} alt="Verified purchase badge" />} */}
                <SpreadFlexRow>
                  <MobileFlexRow>
                    <P>{customerName}</P>
                    <P>{date.toLocaleDateString()}</P>
                  </MobileFlexRow>
                  <FlexRow>
                    <StarGrid cols={stars.length}>
                      {stars.map((_, idx) => (
                        <img key={idx} src={PurpleStar} alt="Star icon" />
                      ))}
                    </StarGrid>
                    <HiddenMobileP>{customerName}</HiddenMobileP>
                  </FlexRow>
                  <HiddenMobileP>{date.toLocaleDateString()}</HiddenMobileP>
                </SpreadFlexRow>
                <QuoteP>{review.body}</QuoteP>
              </QuoteContainer>
            </GradientWrapper>
          )
        })}
      </Grid>
      {pageCount > 1 && <Pagination count={pageCount} page={page} onChange={handleChange} />}
    </Container>
  ) : null
}

export default Reviews

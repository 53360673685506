import React from 'react'
import styled from 'styled-components'

const ColoredPath = styled('path')<{ fillColor: string }>`
  fill: ${props => props.theme.colors[props.fillColor]};
`

export default ({ fill }: { fill: string }) => (
  <svg width="349" height="68" viewBox="0 0 349 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ColoredPath
      d="M127.039 12.719C218.914 -21.2703 300.652 21.4912 348.196 74.6635C389.491 120.858 462.283 222.653 452.54 300.76C442.797 378.867 354.582 433.93 235.651 441.429C190.516 442.078 58.4575 445.132 -31.4549 321.086C-105.201 215.342 -90.034 166.635 -75.6123 143.259C-61.1907 119.883 37.3075 45.8965 127.039 12.719Z"
      fillColor={fill}
    />
  </svg>
)

import React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from '../layouts'
import PDP from '../components/PDP'
import SEO from '../components/shared/SEO'

interface BlobsInterface {
  img: {
    childImageSharp: {
      fluid: any
    }
  }
  blobCss: string
  mobileBlobCss: string
}
interface BenefitInterface {
  label: string
  detail: string
  frontImg: {
    childImageSharp: {
      fluid: any
    }
  }
  backImg: {
    childImageSharp: {
      fluid: any
    }
  }
}

interface SectionInterface {
  label: string
  header: string
  bullets: { title: string; citation: string; link: string }[]
}

interface BadgeInterface {
  name: string
  overrideLabel?: string
}

interface PdpInterface {
  dataJson: {
    sku: string
    bundle: boolean
    color: string
    colorSecondary: string
    gradient: string
    pdpGradient: string
    pdpTransform: string
    slug: string
    title: string
    shortTitle: string
    description: string
    oneLiner: string
    ingredientFact: string
    images: any
    pdpBlobs: BlobsInterface[]
    keyBenefits: BenefitInterface[]
    superfood: string
    research: SectionInterface[]
    certifications: BadgeInterface[]
    childProducts: string[]
    contains: string
    junip: {
      productIDs: string
      remoteProductIDs: string
    }
    seo?: {
      title: string
      description: string
      keywords: string[]
    }
  }
}

interface ExtendedPageProps extends PageProps {
  data: PdpInterface
}

const PdpTemplate = (props: ExtendedPageProps) => {
  const { location, data } = props

  const title = data.dataJson.seo?.title || data.dataJson.title
  const description = data.dataJson.seo?.description || data.dataJson.description
  const keywords = data.dataJson.seo?.keywords || []

  return (
    <>
      <SEO title={title} description={description} keywords={keywords} />
      <Layout location={location}>
        <PDP data={data} />
      </Layout>
    </>
  )
}

export default PdpTemplate

export const query = graphql`
  query($slug: String!) {
    dataJson(fields: { slug: { eq: $slug } }) {
      sku
      bundle
      slug
      color
      colorSecondary
      childProducts
      title
      shortTitle
      description
      oneLiner
      ingredientFact
      gradient
      pdpGradient
      pdpTransform
      contains
      superfood
      images {
        bottles {
          single {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          multipack_2 {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          multipack_4 {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        cardImg {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        benefitsGummy {
          childImageSharp {
            fluid(maxWidth: 40) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        bullet {
          childImageSharp {
            fluid(maxWidth: 40) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        supplementFacts {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        carouselImages {
          childImageSharp {
            fluid(maxWidth: 2500) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        mobileCarouselImages {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      pdpBlobs {
        blobCss
        mobileBlobCss
        img {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      research {
        label
        header
        bullets {
          title
          citation
          link
        }
      }
      keyBenefits {
        label
        detail
      }
      certifications {
        name
        overrideLabel
      }
      junip {
        productIDs
        remoteProductIDs
      }
      seo {
        title
        description
        keywords
      }
    }
  }
`

import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import Img from 'gatsby-image'

import Spinner from 'src/components/shared/Spinner'
import { H2, H3, SmallP } from 'src/components/shared/text'
import { PdpButton } from 'src/components/shared/buttons'

import { media } from '../../../styles/util'

const CardWrapper = styled('div')<{ cardCount: number }>`
  width: 100%;
  display: grid;
  place-items: center;

  ${media.mobile} {
    max-width: 335px;
  }

  ${media.desktop} {
    height: 587px;
    max-width: none;

    &:nth-of-type(2) {
      height: ${props => (props.cardCount === 3 ? '627px;' : '587px')} !important;
    }
  }
`

const Card = styled.div`
  width: 100%;
  height: 100%;

  background-color: white;

  border: 2px solid ${props => props.theme.colors.purple};
  border-radius: 15px;

  display: grid;

  ${media.mobile} {
    grid-template-columns: 91px 1fr;
    grid-gap: 24px 17px;
    padding: 14px 11px;
  }

  ${media.tablet} {
    place-items: center;
    grid-template-columns: 1fr;
    grid-gap: 0 20px;
    padding: 0;
  }
`

const TextBox = styled('div')<{ largeImage: boolean }>`
  position: relative;

  width: 100%;
  height: 100%;

  display: grid;

  & p {
    width: fit-content;
  }

  ${media.mobile} {
    text-align: left;
    grid-row-gap: 17px;

    & h2 {
      font-size 18px;
      line-height: 23px;
    }
  }

  ${media.tablet} {
    place-items: center;
    text-align: center;
    grid-template-rows: 37px min-content ${props =>
      props.largeImage ? '260px' : '200px'} min-content min-content;
    grid-row-gap: 20px;
    padding: 20px 20px 15px 20px;

    & h2 {
      font-size 25px;
      line-height: 33px;
    }
  }
`

const DesktopH2 = styled(H2)`
  ${media.mobile} {
    display: none;
  }
  ${media.tablet} {
    display: block;
  }
`

const MobileH2 = styled(H2)`
  ${media.mobile} {
    display: block;
    margin-bottom: 6px;
  }
  ${media.tablet} {
    display: none;
  }
`

const CtaGroup = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-row-gap: 9px;
  margin-top: -7px;

  ${media.tablet} {
    place-items: center;
    max-width: 264px;
  }
`

const CtaButton = styled(PdpButton)<{ adding: boolean; isOutOfStock: boolean }>`
  ${media.mobile} {
    width: 95%;
    min-width: initial;
    height: 42px;
    border-radius: 13px;

    & h2 {
      font-size 18px;
    }
  }
  ${media.tablet} {
    width: 100%;
    min-width: initial;
    height: 57px;
    border-radius: 20px;

    & h2 {
      font-size 25px;
    }
  }

  &:hover {
    background-color: ${props =>
      props.isOutOfStock
        ? props.theme.colors.grey
        : props.adding
        ? props.theme.colors.purple
        : 'transparent'};
        border-color: ${props =>
          props.isOutOfStock ? props.theme.colors.grey : props.theme.colors.purple};
  }
`

const GradientText = styled(SmallP)`
  font-family: Beacon-Bold, sans-serif;
  font-size: 14px;
  line-height: 22px;

  background: linear-gradient(90deg, #fa6666 7.21%, #f090c3 38.38%, #52d9bf 70.37%, #21a0f8 93.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const GradientPillWrapper = styled.div`
  height: 100%;
  width: fit-content;
  background: linear-gradient(90deg, #fa6666 1.9%, #f090c3 28.98%, #52d9bf 64.74%, #21a0f8 100%);
  display: grid;
  place-items: center;

  ${media.mobile} {
    padding: 2px;
    border-radius: 7px;
  }
  ${media.tablet} {
    padding: 4px;
    border-radius: 10px;
  }
`

const Pill = styled.div`
  background-color: white;
  height: 100%;
  width: fit-content;
  display: grid;
  place-items: center;
  border-radius: 6px;
  padding: 0 14px;

  ${media.mobile} {
    & p {
      font-size: 12px;
    }
  }
  ${media.tablet} {
    & p {
      font-size: 16px;
    }
  }
`

const DesktopImage = styled(Img)`
  ${media.mobile} {
    display: none;
  }
  ${media.tablet} {
    display: block;
  }
`

const MobileImage = styled(Img)`
  ${media.mobile} {
    display: block;
  }
  ${media.tablet} {
    display: none;
  }
`

const DesktopSpan = styled.span`
  ${media.mobile} {
    display: none;
  }
  ${media.tablet} {
    display: block;
    height: 0;
  }
`

const MobileSpan = styled.span`
  ${media.mobile} {
    display: inline-block;
    padding: 0 1px;
  }
  ${media.tablet} {
    display: none;
  }
`

const DiscountGrummyWrapper = styled.div`
  transform: translate(50%, 0);
  position: absolute;
  z-index: 2;

  ${media.mobile} {
    right: -10px;
    top: -9px;
    width: 42px;
    height: 42px;
  }
  ${media.tablet} {
    right: 0;
    top: 9px;
    width: 73px;
    height: 73px;
  }
`

const DiscountText = styled(H3)`
  position: absolute;
  top: 0;
  left: 0;

  text-align: center;
  z-index: 3;

  line-height: 1;

  ${media.mobile} {
    font-size: 12px;
    width: 42px;
    height: 42px;
    padding: 9px 10px 0 10px;
  }
  ${media.tablet} {
    font-size: 18px;
    height: 73px;
    width: 73px;
    padding: 19px 10px 10px 10px;
  }
`

const query = graphql`
  query {
    discountGrummy: file(relativePath: { regex: "/pdp/discount-grummy.png/" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

interface PurchaseCardInterface {
  type: string
  cardCount: number
  productDetail: any
  isSubscription: boolean
  addToCart: (variantId: string, oneTime: boolean) => void
  idx: number
  isOutOfStock: boolean
}

const PurchaseCard = ({
  type,
  cardCount,
  productDetail,
  isSubscription,
  addToCart,
  idx,
  isOutOfStock,
}: PurchaseCardInterface) => {
  if (!productDetail) return null

  const { discountGrummy } = useStaticQuery(query)

  const [adding, setAdding] = useState(false)

  const isBundle = productDetail?.jsonData.bundle
  const isBestSeller = !isSubscription && idx === 1 && (cardCount === 3 || cardCount === 2)

  let packCount = '1'
  if (type.includes('multipack')) packCount = type.split('_')[1]

  const {
    oneTime: { price: otpPrice },
    subscription: { price: subPrice },
    compareAt: { price: compareAtPrice },
  } = productDetail[type].pricing

  const displayedPrice = isSubscription ? subPrice : otpPrice
  const roundedDisplayPrice = displayedPrice.toFixed(0)
  const comparablePrice = isSubscription && !isBundle ? otpPrice : compareAtPrice
  const savings = Number(comparablePrice) - Number(displayedPrice)
  const pricePerBottle = otpPrice / (isBundle ? 2 * Number(packCount) : Number(packCount))

  const bottleImage = productDetail.jsonData.images.bottles[type]

  const servings = 30 * Number(packCount)

  const savingsPercentage = (savings / comparablePrice) * 100
  const roundedSavingsPercentage = Number((savingsPercentage / 5).toFixed(0)) * 5

  const handleAddToCart = async () => {
    if (!isOutOfStock) {
      setAdding(true)
      await addToCart(productDetail[type].variantId, !isSubscription)
      setAdding(false)
    }
  }

  return (
    <CardWrapper cardCount={cardCount}>
      <MobileH2>
        Get a {packCount}-pack {isSubscription ? 'monthly' : `for $${otpPrice.toFixed(0)}`}
      </MobileH2>
      <Card>
        <MobileImage
          fluid={bottleImage.childImageSharp.fluid}
          alt="Product image"
          style={{ width: '100%', height: '100%' }}
          imgStyle={{ objectFit: 'contain' }}
        />

        <TextBox largeImage={cardCount === 3 && idx === 1}>
          {savingsPercentage > 0 && (
            <DiscountGrummyWrapper>
              <Img
                fluid={discountGrummy.childImageSharp.fluid}
                aria-hidden
                style={{ width: '100%', height: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
              />
              <DiscountText>
                {roundedSavingsPercentage.toFixed(0)}%<br />
                off{' '}
              </DiscountText>
            </DiscountGrummyWrapper>
          )}

          {isSubscription || isBestSeller ? (
            <GradientPillWrapper>
              <Pill>
                <GradientText>{isSubscription ? 'Subscribe & Save' : 'Most Popular'}</GradientText>
              </Pill>
            </GradientPillWrapper>
          ) : (
            <div />
          )}
          <DesktopH2>
            Get a {packCount}-pack {isSubscription ? 'monthly' : `for $${otpPrice.toFixed(0)}`}
          </DesktopH2>
          <DesktopImage
            fluid={bottleImage.childImageSharp.fluid}
            alt="Product image"
            style={{ width: '100%', height: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
          />
          <H2>
            Only $
            {isSubscription ? `${roundedDisplayPrice} per month` : `${pricePerBottle} per bottle`}
            <br /> <em>You save ${savings.toFixed(0)}</em>
          </H2>
          <CtaGroup>
            <SmallP>
              {servings} servings{isBundle && ' of each'}
            </SmallP>
            <CtaButton onClick={handleAddToCart} adding={adding} isOutOfStock={isOutOfStock}>
              <H2>
                {adding ? (
                  <Spinner color="white" size={30} />
                ) : (
                  <span>
                    {isOutOfStock
                      ? 'Sold out!'
                      : isSubscription
                      ? 'Subscribe & save'
                      : 'Add to cart'}
                  </span>
                )}
              </H2>
            </CtaButton>
            <GradientText>
              Free Shipping
              <DesktopSpan />
              {isSubscription && (
                <>
                  <MobileSpan> / </MobileSpan>Cancel anytime
                </>
              )}
            </GradientText>
          </CtaGroup>
        </TextBox>
      </Card>
    </CardWrapper>
  )
}

export default PurchaseCard

import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { H1, H2 } from '../shared/text'
import { media } from '../../styles/util'

import Arrow from '../../assets/img/arrows/pdp-arrow.svg'

const Container = styled.div`
  width: 100%;
  height: fit-content;
  display: grid;
  place-items: center;
  text-align: center;

  & h1 {
    z-index: 10;
  }

  & > h1:last-of-type {
    max-width: 1066px;
    margin: 0 auto 27px auto;
    z-index: 1;
  }

  ${media.mobile} {
    padding: 0 24px 60px 24px;
  }

  ${media.tablet} {
    padding: 0 40px;
    margin-bottom: 60px;

    & h2 {
      font-size: 30px;
      line-height: 35px;
    }
  }
`

const TopQuote = styled.div`
  position: relative;
  max-width: 900px;
  z-index: 1;

  display: grid;
  place-items: center;

  ${media.mobile} {
    margin: 0 auto 150px auto;
    min-height: 144px;
  }
  ${media.tablet} {
    margin: 0 auto 220px auto;
    height: fit-content;
  }
`

const Factoid = styled(H2)`
  max-width: 500px;
  white-space: pre-line;
  text-align: center;

  ${media.mobile} {
    margin: 27px auto 0 auto;
    font-family: Beacon, sans-serif;
    font-size: 16px;
    line-height: 20px;
  }
  ${media.tablet} {
    margin: 53px auto 10px auto;
    font-family: Cooper, sans-serif;
    font-size: 30px;
    line-height: 35px;
  }
`

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const SmallBlobImg = styled.div`
  animation: ${rotateAnimation} 20s linear infinite;

  ${media.mobile} {
    width: 62px;
  }
  ${media.tablet} {
    width: 109px;
  }
`

const ArrowImg = styled.img`
  position: absolute;
  left: 45%;
  transform: translate(-50%, 100%);
  z-index: 0;
  bottom: 0;

  ${media.mobile} {
    top: 10%;
    min-width: 150vw;
    max-height: 140px;
  }
  ${media.tablet} {
    top: 40%;
    width: 96vw;
    min-width: initial;
    max-height: none;
  }
  ${media.desktop} {
    top: auto;
    height: auto;
    max-width: 978px;
  }
`

const query = graphql`
  query {
    purpleBlob: file(relativePath: { regex: "/whats-inside/purple-blob-round.png/" }) {
      childImageSharp {
        fluid(maxWidth: 62) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

interface QuotesInterface {
  description: string
  oneLiner: string
  ingredientFact: string
}

const Quotes = ({ description, oneLiner, ingredientFact }: QuotesInterface) => {
  const { purpleBlob } = useStaticQuery(query)

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    const baseConfig = {
      opacity: 0,
      duration: 1,
      delay: 0.35,
    }
    gsap.from('.pdp__quotes--description', {
      scrollTrigger: '.pdp__quotes--description',
      ...baseConfig,
    })
    gsap.from('.pdp__quotes--arrow', {
      scrollTrigger: '.pdp__quotes--arrow',
      ...baseConfig,
    })
    gsap.from('.pdp__quotes--one-liner', {
      scrollTrigger: '.pdp__quotes--one-liner',
      ...baseConfig,
    })
    gsap.from('.pdp__quotes--blob', {
      scrollTrigger: '.pdp__quotes--blob',
      transform: 'scale(0.2)',
      opacity: 0,
      duration: 1,
      delay: 0.5,
    })
    gsap.from('.pdp__quotes--factoid', {
      scrollTrigger: '.pdp__quotes--factoid',
      opacity: 0,
      duration: 1,
      delay: 0.25,
    })
  }, [])

  return (
    <Container>
      <TopQuote>
        <H1 className="pdp__quotes--description">{description}</H1>
        <ArrowImg src={Arrow} aria-hidden className="pdp__quotes--arrow" />
      </TopQuote>

      <H1 className="pdp__quotes--one-liner">{oneLiner}</H1>

      <SmallBlobImg className="pdp__quotes--blob">
        <Img fluid={purpleBlob.childImageSharp.fluid} aria-hidden="true" />
      </SmallBlobImg>

      <Factoid className="pdp__quotes--factoid">{ingredientFact}</Factoid>
    </Container>
  )
}

export default Quotes

import React, { useContext, useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { gsap } from 'gsap'

import ProductSelector from './ProductSelector'
import Reviews from './Reviews'
import Afterpay from './Afterpay'
import Spinner from '../../shared/Spinner'
import { PdpButton } from '../../shared/buttons'
import { H1, H2, H3, P } from '../../shared/text'
import { media } from '../../../styles/util'
import { trackProductViewEvent } from '../../../utils/event-tracking'

import StoreContext from '../../../context/StoreContext'

const wiggle = keyframes`
  0% { transform: rotate(0deg); }
  30% { transform: rotate(2deg) scale(1.1); }
  70% { transform: rotate(-2deg) scale(1.1); }
  100% { transform: rotate(0deg); }
`

const Container = styled('div')<{ oneTime: boolean }>`
  position: relative;
  width: 100%;
  height: fit-content;

  ${media.mobile} {
    padding: 109px 20px ${props => (props.oneTime ? '46px' : '64px')} 20px;
  }
  ${media.tablet} {
    padding: 180px 20px 70px 20px;
  }
  ${media.desktop} {
    min-height: 105vh;
    padding: 233px 100px 96px 100px;
  }
`

const GradientBackground = styled('div')<{ customCss: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  ${props => props.customCss};
`

const ContentGrid = styled.div`
  position: relative;

  width: 100%;
  max-width: 980px;
  margin: 0 auto;

  display: grid;
  place-items: center;
  z-index: 100;

  ${media.mobile} {
    grid-row-gap: 23px;
    text-align: center;
  }
  ${media.desktop} {
    grid-template-columns: auto minmax(450px, min-content);
    grid-column-gap: 30px;
    text-align: left;
  }
`

const LogoWrapper = styled(Link)`
  &:hover {
    animation: ${wiggle} 250ms;
  }

  position: absolute;
  z-index: 30;
  ${media.mobile} {
    top: 75px;
    left: 21px;
    width: 62px;
    height: 62px;
  }
  ${media.tablet} {
    top: 45px;
    left: 80px;
    width: 144px;
    height: 144px;
  }
`

const BottleImage = styled('div')<{ isBundle: boolean }>`
  height: auto;
  object-fit: contain;
  z-index: 100;

  ${media.mobile} {
    width: ${props => (props.isBundle ? '70vw' : '50vw')};
  }
  ${media.tablet} {
    width: 300px;
  }
  ${media.desktop} {
    width: ${props => (props.isBundle ? '450px' : '375px')};
    margin-top: ${props => (props.isBundle ? '80px' : '30px')};
  }
`

const TextGroupGrid = styled.div`
  width: 100%;
  height: 100%;
  display: grid;

  ${media.mobile} {
    max-width: 90vw;
    justify-items: center;
    grid-row-gap: 14px;
  }
  ${media.desktop} {
    grid-template-rows: min-content minmax(30px, min-content) 86px 253px;
    justify-items: start;
    grid-row-gap: 10px;
    max-width: 525px;
  }

  & a {
    text-decoration: underline;
    margin-left: 10px;
    &:hover {
      color: ${props => props.theme.colors.orange};
      text-decoration: none;
    }
  }
`

const pulse = keyframes`
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.07);
            transform: scale(1.07);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
`

const FreeShippingBlob = styled.div`
  -webkit-animation: ${pulse} 5s both infinite;
  animation: ${pulse} 5s both infinite;

  position: absolute;
  z-index: 10;

  ${media.mobile} {
    width: 54px;
    height: 54px;
    top: 250px;
    right: 0;
  }
  ${media.desktop} {
    width: 84px;
    height: 84px;
    top: -120px;
    right: 40px;
  }
`

const FlexRow = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;

  margin: 9px auto 0 auto;

  ${media.tablet} {
    justify-items: center;
    & button {
      margin: 10px 0 20px 90px;
      max-width: 300px;
    }
  }

  ${media.desktop} {
    justify-items: flex-start;
    margin: 0;

    & button {
      margin: 0;
    }
  }
`

const BlobWrapper = styled('div')<{ blobCss: string; mobileBlobCss: string }>`
  position: absolute;
  max-width: 730px;
  z-index: -1;

  ${media.mobile} {
    ${props => props.mobileBlobCss};
  }
  ${media.tablet} {
    ${props => props.blobCss};
  }
`

// const BundleContainsText = styled(H2)`
//   margin: 0 0 10px 0;
// `

const DesktopCta = styled(PdpButton)<{ isOutOfStock: boolean }>`
  ${media.mobile} {
    display: none;
  }
  ${media.desktop} {
    display: block;
    margin-right: 12px;
  }

  &:hover {
    background-color: ${props => (props.isOutOfStock ? props.theme.colors.grey : 'transparent')};
    border-color: ${props =>
      props.isOutOfStock ? props.theme.colors.grey : props.theme.colors.purple};
  }
`

const MobileCta = styled(PdpButton)<{ isOutOfStock: boolean }>`
  margin: 24px auto 6px auto;

  ${media.mobile} {
    width: 300px;
    height: 70px;
    & h2 {
      font-size: 22px;
      line-height: 1;
    }
  }
  ${media.tablet} {
    width: 264px;
    & h2 {
      font-size: 25px;
      line-height: 1;
    }
  }
  ${media.desktop} {
    display: none;
  }

  &:hover {
    background-color: ${props => (props.isOutOfStock ? props.theme.colors.grey : 'transparent')};
    border-color: ${props =>
      props.isOutOfStock ? props.theme.colors.grey : props.theme.colors.purple};
  }
`

const LoadableContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  ${media.mobile} {
    min-height: 300px;
  }
  ${media.desktop} {
    min-height: 334px;
    min-width: 525px;
  }
`

const HideMobile = styled.div`
  ${media.mobile} {
    display: none;
  }
  ${media.desktop} {
    display: block;
  }
`

const HideDesktop = styled.div`
  margin-top: 9px;
  ${media.desktop} {
    display: none;
  }
`

const SubscriptionDetailText = styled(P)<{ color?: string }>`
  margin-left: 12px;
  color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.purple)};
`

const query = graphql`
  query {
    logo: file(relativePath: { regex: "/logo-purple.png/" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    freeShippingBlob: file(relativePath: { regex: "/pdp/free-shipping-blob.png/" }) {
      childImageSharp {
        fluid(maxWidth: 94) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    afterpay: file(relativePath: { regex: "/pdp/afterpay-logo.png/" }) {
      childImageSharp {
        fluid(maxWidth: 94) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

interface BlobsInterface {
  img: {
    childImageSharp: {
      fluid: any
    }
  }
  blobCss: string
  mobileBlobCss: string
}

interface ReviewStats {
  totalReviewCount: number
  averageRating: number
}

interface DataInterface {
  dataJson: {
    sku: string
    bundle: boolean
    slug: string
    title: string
    shortTitle: string
    description: string
    images: any
    pdpGradient: string
    pdpTransform: string
    pdpBlobs: BlobsInterface[]
    contains: string
    color: string
  }
}

const GatsbyImage = ({ fluid, alt }: { fluid: any; alt: string }) => (
  <Img
    fluid={fluid}
    alt={alt}
    style={{ height: '100%', width: '100%' }}
    imgStyle={{ objectFit: 'contain' }}
  />
)

const AboveFold = ({
  data,
  reviewStats,
  otpOptions,
  isOutOfStock,
}: {
  data: DataInterface
  reviewStats: ReviewStats
  otpOptions: string[]
  isOutOfStock: boolean
}) => {
  const { logo, afterpay, freeShippingBlob } = useStaticQuery(query)

  const { loading, store, products, addVariantToCart, setCartMenuOpen } = useContext(StoreContext)

  const [revealPricing, setRevealPricing] = useState(false) // For revealing product selectors
  const [adding, setAdding] = useState(false)
  const [productType, setProductType] = useState('single')
  const [oneTime, setOneTime] = useState(false)

  const productSku = data.dataJson.sku
  const productDetail = products.byProduct[productSku]

  useEffect(() => {
    gsap.from(`.pdp__blob--${productSku}`, {
      delay: 0.5,
      opacity: 0,
      scale: 0.2,
      duration: 0.35,
      ease: 'ease-in-out',
    })
    gsap.to(`.pdp__blob--${productSku}`, {
      duration: 'random(4, 6)',
      yoyo: true,
      repeat: -1,
      ease: 'ease-in-out',
      y: 'random(16, 32)',
    })
  }, [])

  const optionCount = otpOptions.length

  useEffect(() => {
    if (!loading && productDetail) {
      if (!productDetail?.single?.pricing?.subscription) {
        setOneTime(true)
      }

      setRevealPricing(true)

      const { slug, shortTitle } = productDetail.jsonData

      const allProductVariantIds: string[] = []
      Object.keys(productDetail).forEach((variantKey: string) => {
        const { variantId } = productDetail[variantKey]
        if (variantId) allProductVariantIds.push(variantId)
      })

      trackProductViewEvent(
        shortTitle.toLowerCase(),
        slug,
        allProductVariantIds,
        productDetail.single.price
      )
    }
  }, [loading, productDetail])

  let afterpayCopy = ''
  let displayedCtaPrice = ''
  let smartrrSellingPlan = ''

  if (productDetail) {
    const oneTimePrice = productDetail[productType].pricing.oneTime.price
    const originalSubPrice = productDetail.single.pricing.subscription.price
    const ctaPrice = !oneTime && originalSubPrice ? originalSubPrice : oneTimePrice
    const afterpayPrice = ctaPrice / 4

    afterpayCopy = ctaPrice % 4 === 0 ? afterpayPrice.toFixed(0) : afterpayPrice.toFixed(2)
    displayedCtaPrice = ctaPrice.toFixed(0)
    smartrrSellingPlan = productDetail.single.pricing.subscription
  }

  const handleAddToCart = async () => {
    if (!adding && store && store.cart && !isOutOfStock) {
      setAdding(true)
      const isSubscriptionPurchase = !oneTime && smartrrSellingPlan
      const otpVariantId = productDetail[productType]?.variantId
      await addVariantToCart(otpVariantId, 1, isSubscriptionPurchase ? smartrrSellingPlan : null)
      setAdding(false)
      setCartMenuOpen(true)
    }
  }

  return (
    <Container oneTime={oneTime}>
      <GradientBackground
        customCss={`background: ${data.dataJson.pdpGradient}; transform: ${data.dataJson.pdpTransform};`}
      />

      {data.dataJson.pdpBlobs.map(blob => (
        <BlobWrapper
          blobCss={blob.blobCss}
          mobileBlobCss={blob.mobileBlobCss}
          className={`pdp__blob--${productSku}`}
          key={blob.blobCss}
        >
          <Img fluid={blob.img.childImageSharp.fluid} aria-hidden="true" />
        </BlobWrapper>
      ))}

      <LogoWrapper to="/">
        <GatsbyImage fluid={logo.childImageSharp.fluid} alt="Grummies logo" />
      </LogoWrapper>

      <ContentGrid>
        {revealPricing && (
          <FreeShippingBlob className="pdp__free-shipping">
            <GatsbyImage fluid={freeShippingBlob.childImageSharp.fluid} alt="Free Shipping" />
          </FreeShippingBlob>
        )}

        <BottleImage isBundle={data.dataJson.bundle}>
          <GatsbyImage
            fluid={data.dataJson.images.bottles.single.childImageSharp.fluid}
            alt={`${data.dataJson.title} bottle`}
          />
        </BottleImage>

        <TextGroupGrid>
          <H1>{data.dataJson.shortTitle}</H1>
          <div>
            {/* {data.dataJson.contains && (
              <BundleContainsText>{data.dataJson.contains}</BundleContainsText>
            )} */}
            <Reviews reviewStats={reviewStats} />
            <H3 style={{ marginBottom: 14 }}>60 vegan gummies per bottle</H3>
          </div>

          <LoadableContentWrapper>
            {!revealPricing ? (
              <Spinner color={props => props.theme.colors.purple} size={50} />
            ) : (
              <>
                <HideMobile>
                  <FlexRow>
                    <DesktopCta
                      onClick={handleAddToCart}
                      adding={adding}
                      isOutOfStock={isOutOfStock}
                    >
                      {adding ? (
                        <Spinner color="white" size={30} />
                      ) : (
                        <H2>
                          {isOutOfStock
                            ? 'Sold out!'
                            : `$${displayedCtaPrice} ${!oneTime ? ' / mo' : ''} - Add to cart`}
                        </H2>
                      )}
                    </DesktopCta>

                    {isOutOfStock ? (
                      <SubscriptionDetailText color="orange">
                        <b>Sold out!</b>
                      </SubscriptionDetailText>
                    ) : oneTime ? (
                      <Afterpay price={afterpayCopy} fluid={afterpay.childImageSharp.fluid} />
                    ) : (
                      <SubscriptionDetailText>
                        1 bottle per month,
                        <br />
                        cancel <b>anytime</b>
                      </SubscriptionDetailText>
                    )}
                  </FlexRow>
                </HideMobile>

                {optionCount > 1 && (
                  <ProductSelector
                    otpOptions={otpOptions}
                    productDetail={productDetail}
                    revealPricing={revealPricing}
                    productType={productType}
                    setProductType={setProductType}
                    bottleImage={data.dataJson.images.bottles.single.childImageSharp.fluid}
                    productTitle={data.dataJson.title}
                    textColor={data.dataJson.color}
                    oneTime={oneTime}
                    setOneTime={setOneTime}
                    smartrrAvailable={!!smartrrSellingPlan}
                  />
                )}

                <div>
                  <MobileCta onClick={handleAddToCart} adding={adding} isOutOfStock={isOutOfStock}>
                    {adding ? (
                      <Spinner color="white" size={30} />
                    ) : (
                      <H2>
                        {isOutOfStock
                          ? 'Sold out!'
                          : `$${displayedCtaPrice} ${!oneTime ? ' / mo' : ''} - Add to cart`}
                      </H2>
                    )}
                  </MobileCta>
                  <HideDesktop>
                    {isOutOfStock ? (
                      <SubscriptionDetailText color="orange">
                        <b>Sold out!</b>
                      </SubscriptionDetailText>
                    ) : oneTime ? (
                      <Afterpay price={afterpayCopy} fluid={afterpay.childImageSharp.fluid} />
                    ) : (
                      <SubscriptionDetailText>
                        1 bottle per month,
                        <br />
                        cancel <b>anytime</b>
                      </SubscriptionDetailText>
                    )}
                  </HideDesktop>
                </div>
              </>
            )}
          </LoadableContentWrapper>
        </TextGroupGrid>
      </ContentGrid>
    </Container>
  )
}

export default AboveFold

import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

import AboveFold from './AboveTheFold'
import Quotes from './Quotes'
import RealNutrition from './RealNutrition'
import Research from './Research'
import OtherProducts from './OtherProducts'
import Reviews from './Reviews'
import Purchase from './Purchase'
import HappinessGuarantee from './HappinessGuarantee'
import ImageCarousel from '../shared/ImageCarousel'
import { H1 } from '../shared/text'
import { media } from '../../styles/util'

import StoreContext from '../../context/StoreContext'
import useReviews from '../../hooks/useReviews'

const Main = styled.main`
  width: 100%;
  height: fit-content;
  display: grid;
  place-items: center;

  ${media.mobile} {
    text-align: center;
  }
`

const ImageWrapper = styled.div`
  width: calc(100% - 16px);
  background-color: ${props => props.theme.colors.grey};
  box-shadow: 16px 16px 0px ${props => props.theme.colors.purple};
  overflow: hidden;
  display: grid;
  place-items: center;
  height: auto;

  ${media.mobile} {
    border-radius: 42px;
  }
  ${media.tablet} {
    border-radius: 32px;
  }
`

const DesktopOnly = styled.div`
  ${media.mobile} {
    display: none;
  }
  ${media.tablet} {
    display: block;
    width: 100%;
  }
`

const MobileOnly = styled.div`
  width: 100%;
  ${media.tablet} {
    display: none;
  }
`

interface BlobsInterface {
  img: {
    childImageSharp: {
      fluid: any
    }
  }
  blobCss: string
  mobileBlobCss: string
}
interface BenefitInterface {
  label: string
  detail: string
  frontImg: {
    childImageSharp: {
      fluid: any
    }
  }
  backImg: {
    childImageSharp: {
      fluid: any
    }
  }
}

interface SectionInterface {
  label: string
  header: string
  bullets: { title: string; citation: string; link: string }[]
}

interface Badge {
  name: string
  overrideLabel?: string
}

interface DataInterface {
  dataJson: {
    sku: string
    bundle: boolean
    gradient: string
    pdpGradient: string
    pdpTransform: string
    slug: string
    title: string
    shortTitle: string
    description: string
    oneLiner: string
    ingredientFact: string
    images: any
    pdpBlobs: BlobsInterface[]
    keyBenefits: BenefitInterface[]
    superfood: string
    research: SectionInterface[]
    certifications: Badge[]
    childProducts: string[]
    contains: string
    color: string
    colorSecondary: string
    junip: {
      productIDs: string
      remoteProductIDs: string
    }
  }
}

const PDP = ({ data }: { data: DataInterface }) => {
  const bottleImage = data.dataJson.images.bottles.single.childImageSharp.fluid
  const productName = data.dataJson.shortTitle
  const { childProducts, junip } = data.dataJson

  const { products } = useContext(StoreContext)
  const {
    reviews,
    reviewStats,
    reviewFilters,
    filterProduct,
    fetchReviews,
    reviewProducts,
  } = useReviews()

  useEffect(() => {
    filterProduct(junip.remoteProductIDs)
    const handleReviewsFetch = async () => {
      await fetchReviews()
    }
    handleReviewsFetch()
  }, [])

  const productMap = products.byProduct
  const productMapAvailable = Object.keys(productMap).length > 0

  let productDetails = [data.dataJson]

  const isBundle = data.dataJson.bundle
  if (productMapAvailable && isBundle) {
    productDetails = childProducts.map((sku: string) => {
      return productMap[sku].jsonData
    })
  }

  const { carouselImages = [], mobileCarouselImages = [] } = data.dataJson.images

  const otpOptions = !data.dataJson.bundle
    ? ['multipack_2', 'multipack_4']
    : ['single', 'multipack_2']

  const isOutOfStock = true

  return (
    <Main>
      <AboveFold
        data={data}
        reviewStats={reviewStats}
        otpOptions={otpOptions}
        isOutOfStock={isOutOfStock}
      />
      <Quotes
        oneLiner={data.dataJson.oneLiner}
        description={data.dataJson.description}
        ingredientFact={data.dataJson.ingredientFact}
      />
      {productMapAvailable &&
        productDetails.map(childProduct => {
          const titleOne = !data.dataJson.bundle
            ? 'Real Nutrition'
            : `${childProduct.shortTitle} Nutrition`

          return (
            <React.Fragment key={childProduct.sku}>
              <RealNutrition title={titleOne} data={childProduct} />
            </React.Fragment>
          )
        })}
      <H1>Research</H1>
      <Research research={data.dataJson.research} />
      {carouselImages.length > 0 && (
        <DesktopOnly>
          <ImageCarousel>
            {carouselImages.map((image: any, idx: number) => (
              <ImageWrapper key={image.childImageSharp.fluid.src}>
                <Img
                  fluid={image.childImageSharp.fluid}
                  alt={`Carousel image ${idx}`}
                  style={{ height: '100%', width: '100%' }}
                />
              </ImageWrapper>
            ))}
          </ImageCarousel>
        </DesktopOnly>
      )}

      {mobileCarouselImages.length > 0 && (
        <MobileOnly>
          <ImageCarousel>
            {mobileCarouselImages.map((image: any, idx: number) => (
              <ImageWrapper key={image.childImageSharp.fluid.src}>
                <Img
                  fluid={image.childImageSharp.fluid}
                  alt={`Carousel image ${idx}`}
                  style={{ height: '100%', width: '100%' }}
                />
              </ImageWrapper>
            ))}
          </ImageCarousel>
        </MobileOnly>
      )}
      <Purchase data={data} otpOptions={otpOptions} isOutOfStock={isOutOfStock} />
      <Reviews
        bottleImage={bottleImage}
        reviews={reviews}
        reviewFilters={reviewFilters}
        reviewProducts={reviewProducts}
        filterProduct={filterProduct}
      />
      <HappinessGuarantee productName={productName} isBundle={isBundle} />
      <OtherProducts currentSku={data.dataJson.sku} />
    </Main>
  )
}

export default PDP

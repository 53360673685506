import React from 'react'
import styled from 'styled-components'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'

import ExpandIcon from 'src/components/shared/icons/nutrition/expand-icon'
import { H3, P } from 'src/components/shared/text'

const StyledAccordion = styled(Accordion)<{ textColor: string }>`
  height: fit-content;
  margin: 0 !important;

  text-align: left;
  color: ${props => props.theme.colors[props.textColor]} !important;

  border: none !important;
  box-shadow: none !important;

  div.MuiAccordionSummary-root.Mui-expanded {
    height: 48px !important;
    max-height: 48px !important;
    min-height: 48px !important;
  }

  .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(-225deg) !important;
  }
`

const StyledAccordionSummary = styled(AccordionSummary)`
  min-height: none !important;
  padding: 0 3px 0 14px !important;

  & h3 {
    font-size: 18px;
    line-height: 1;
    text-transform: capitalize;
  }

  & > div {
    min-height: none !important;
    margin: 0 !important;
  }

  &::before {
    border: none !important;
    box-shadow: none !important;
  }
`

const StyledAccordionDetails = styled(AccordionDetails)`
  border: none !important;
  box-shadow: none !important;
  padding-top: 0 !important;

  & p {
    font-size: 14px;
    line-height: 18px;
  }
`

interface BenefitAccordionInterface {
  label: string
  detail: string
  textColor: string
  idx: number
  expandedBenefit: number
  setExpandedBenefit: (panel: number) => void
}

const BenefitAccordion = ({
  label,
  detail,
  textColor,
  idx,
  expandedBenefit,
  setExpandedBenefit,
}: BenefitAccordionInterface) => {
  const handleChange = (panel: number) => (_: any, isExpanded: boolean) => {
    setExpandedBenefit(isExpanded ? panel : 0)
  }

  const panelName = `panel${idx}`

  return (
    <StyledAccordion
      expanded={expandedBenefit === idx}
      onChange={handleChange(idx)}
      textColor={textColor}
    >
      <StyledAccordionSummary
        expandIcon={<ExpandIcon fill={textColor} />}
        aria-controls={`${panelName}bh-content`}
        id={`${panelName}bh-header`}
      >
        <H3>{label}</H3>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <P>{detail}</P>
      </StyledAccordionDetails>
    </StyledAccordion>
  )
}

export default BenefitAccordion

import React from 'react'
import styled from 'styled-components'

const ColoredPath = styled('path')<{ fillColor: string }>`
  fill: ${props => props.theme.colors[props.fillColor]};
`

export default ({ fill }: { fill: string }) => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ColoredPath
      d="M17.4276 7.26643C17.4002 7.2508 17.3768 7.23908 17.3494 7.22736C17.7447 6.60223 17.913 5.82862 17.7643 5.0433C17.4512 3.39451 15.8621 2.31224 14.2104 2.62481C14.183 2.62872 14.1517 2.63653 14.1243 2.64435C14.0304 1.90981 13.6664 1.20654 13.048 0.698616C11.7525 -0.371927 9.83464 -0.192201 8.76614 1.10105C8.74657 1.12449 8.73095 1.14793 8.71138 1.16747C8.16735 0.663451 7.43935 0.354791 6.64091 0.354791C4.96184 0.354791 3.59979 1.71446 3.59979 3.3906C3.59979 3.53907 3.61152 3.68754 3.63108 3.82819C2.77785 3.72661 1.8855 3.98448 1.20448 4.61743C-0.028405 5.7583 -0.0988688 7.68058 1.04399 8.91132C1.06356 8.93476 1.08706 8.95039 1.10663 8.97383C0.535199 9.44659 0.132062 10.1264 0.026386 10.9196C-0.192793 12.584 0.977492 14.1077 2.64482 14.3265C2.67222 14.3305 2.70352 14.3344 2.73091 14.3344C2.59001 15.0611 2.71523 15.8425 3.14185 16.5184C4.03422 17.9406 5.91293 18.3665 7.3376 17.4757C7.36108 17.46 7.38454 17.4444 7.41194 17.4288C7.7681 18.0773 8.36302 18.6009 9.12623 18.847C10.7231 19.3706 12.4413 18.5071 12.9658 16.913C12.9736 16.8857 12.9814 16.8583 12.9893 16.831C13.6781 17.1045 14.4688 17.124 15.2163 16.8349C16.7819 16.2215 17.5529 14.4594 16.9384 12.8965C16.9266 12.8692 16.9149 12.8418 16.9032 12.8184C17.6077 12.5879 18.2339 12.1034 18.6214 11.4001C19.4316 9.92325 18.8993 8.0752 17.4276 7.26643Z"
      fillColor={fill}
    />
    <path
      d="M14.9073 8.71162H9.67969V3.48404C9.67969 3.2167 9.46298 3 9.19565 3C8.92832 3 8.71162 3.2167 8.71162 3.48404V8.71162H3.48404C3.2167 8.71162 3 8.92832 3 9.19565C3 9.46298 3.2167 9.67969 3.48404 9.67969H8.71162V14.9073C8.71162 15.1746 8.92832 15.3913 9.19565 15.3913C9.46298 15.3913 9.67969 15.1746 9.67969 14.9073V9.67969H14.9073C15.1746 9.67969 15.3913 9.46298 15.3913 9.19565C15.3913 8.92832 15.1746 8.71162 14.9073 8.71162Z"
      fill="white"
    />
  </svg>
)

import { useEffect, useState } from 'react'
import axios from 'axios'

// When new products/SKUs are added, update this hash table so that reviews can be pulled into
// filter dropdown
// const reviewProducts = {
//   '6130036998339': 'Apple Cider Vinegar',
//   '6130028249283': 'Ashwagandha',
//   '6130039455939': 'Elderberry',
//   '6130045649091': 'Turmeric',
//   '6130035720387': 'Spirulina',
//   '6130053677251': 'Fit Bundle',
//   '6130053120195': 'Relax Bundle'
// }

// Finn TODO:
const reviewProducts: { [key: string]: string } = {
  4418322301010: 'Hip & Joint',
  4501744123986: 'Skin & Coat',
  4501743239250: 'Multivitamin',
  4501744386130: 'Calming Aid',
  4879590228050: 'Active Pup Bundle',
  4715572592722: 'Senior Pup Bundle',
  4715589206098: 'Super Pup Bundle',
  4878540800082: 'Pretty Pup Bundle',
  4754716328018: 'Chill Pup Bundle',
}

interface JunipCustomer {
  id: number
  created_at: string
  first_name: string
  last_name: string
  updated_at: string
}

interface JunipProductReview {
  id: number
  body: string
  created_at: string
  updated_at: string
  customer: JunipCustomer
  rating_count: number
  rating_average: number
  customer_id: number
  down_vote_count: number
  featured: boolean
  photo_urls: { large: string; thumbnail: string }[]
  product_id: number
  rating: number
  response: string
  title: string
  verified_buyer: boolean
}

interface ReviewStats {
  totalReviewCount: number
  averageRating: number
}

const junipFetch = axios.create({
  baseURL: 'https://api.juniphq.com/',
  headers: {
    // 'Junip-Store-Key': 'e7nX5hMPUK1zb3XmiZKruJyi' // Grummies
    'Junip-Store-Key': 'FrKnPokoncXn6y8NzwyNuGpC', // Finn TODO:
  },
})

// const updateObjectInArray = (oldArr: JunipProductReview[], id: number, newObj: JunipProductReview): JunipProductReview[] =>
//   oldArr.map(item => {
//     if (item.id !== id) return item
//     return newObj
//   })

export default () => {
  const [reviews, setReviews] = useState([])
  const [loading, setLoading] = useState(true)
  const [reviewStats, setReviewStats] = useState<ReviewStats>({
    totalReviewCount: 0,
    averageRating: 0,
  })
  const [reviewFilters, setReviewFilters] = useState({})

  const PRODUCT_QUERY = `/v1/products?filter%5Bremote_ids%5D=${Object.keys(reviewProducts).join(
    ','
  )}`
  const filterProductArray = Object.keys(reviewFilters).join(',')
  const REVIEWS_QUERY = `/v1/product_reviews?include=customer${
    filterProductArray ? `&filter%5Bproduct_remote_ids%5D=${filterProductArray}` : ''
  }`

  useEffect(() => {
    const initalizeReviews = async (): Promise<void> => {
      const { data: reviewData } = await junipFetch.get(REVIEWS_QUERY) // review content
      const { data: productData } = await junipFetch.get(PRODUCT_QUERY) // total number of reviews + stars

      const totalReviewCount = productData.products.reduce(
        (sum: number, product: JunipProductReview) => sum + product.rating_count,
        0
      )
      const totalStars = productData.products.reduce(
        (sum: number, product: JunipProductReview) =>
          sum + product.rating_count * (product.rating_average || 0),
        0
      )
      setReviewStats({
        totalReviewCount,
        averageRating: totalStars / totalReviewCount,
      })
      setReviews(reviewData.product_reviews)
      setLoading(false)
    }
    initalizeReviews()
  }, [])

  useEffect(() => {
    const filterReviews = async (): Promise<void> => {
      const { data: reviewData } = await junipFetch.get(REVIEWS_QUERY) // review content
      setReviews(reviewData.product_reviews)
    }
    if (!loading) filterReviews()
  }, [reviewFilters])

  async function fetchReviews(): Promise<void> {
    const { data: reviewData } = await junipFetch.get(REVIEWS_QUERY)
    setReviews(reviewData.product_reviews)
  }

  // async function vote(id: number, direction: string): Promise<void> {
  //   const {
  //     data: { product_review }
  //   } = await junipFetch.put(`/v1/product_reviews/${id}/${direction}?include=customer`)
  //   const updatedReviews = updateObjectInArray(reviews, id, product_review)
  //   setReviews(updatedReviews)
  // }

  const toggleFilter = (remoteProductId: string) => {
    const updatedFilters: { [key: string]: string } = { ...reviewFilters }

    if (updatedFilters[remoteProductId]) {
      delete updatedFilters[remoteProductId]
    } else {
      const productName: string = reviewProducts[remoteProductId]
      updatedFilters[remoteProductId] = productName
    }

    setReviewFilters(updatedFilters)
  }

  const filterProduct = (key: string) => {
    toggleFilter(String(key))
  }

  return {
    reviews,
    reviewStats,
    reviewFilters,
    filterProduct,
    fetchReviews,
    reviewProducts,
    loading,
  }
}

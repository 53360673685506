import React from 'react'
import styled from 'styled-components'

const ColoredPath = styled('path')<{ fillColor: string }>`
  fill: ${props => props.theme.colors[props.fillColor]};
`

export default ({ fill }: { fill: string }) => (
  <svg width="259" height="75" viewBox="0 0 259 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ColoredPath
      d="M346.803 246.71C362.542 343.398 304.898 415.418 243.595 451.882C190.339 483.551 76.4734 535.47 1.68457 510.929C-73.1043 486.388 -110.227 389.251 -94.7788 271.089C-86.7602 226.668 -64.4321 96.4745 74.5545 32.02C192.479 -20.0782 237.373 4.1484 257.55 22.7853C277.726 41.4222 331.449 152.281 346.803 246.71Z"
      fillColor={fill}
    />
  </svg>
)

import React from 'react'
import styled from 'styled-components'

const ColoredPath = styled('path')<{ fillColor: string }>`
  fill: ${props => props.theme.colors[props.fillColor]};
`

export default ({ fill }: { fill: string }) => (
  <svg
    width="150"
    height="517"
    viewBox="0 0 150 517"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ColoredPath
      d="M571.45 306.352C406.565 405.967 141.925 415.396 91.4667 325.845C41.008 236.294 136 86.8843 304.054 -7.80773C472.108 -102.5 559.978 -56.5206 610.437 33.0304C660.895 122.581 712.409 221.008 571.45 306.352Z"
      fillColor={fill}
    />
  </svg>
)

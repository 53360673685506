import React, { useContext } from 'react'
import styled from 'styled-components'

import SmallProductCard from '../shared/SmallProductCard'
import { H2 } from '../shared/text'
import { media } from '../../styles/util'

import StoreContext from '../../context/StoreContext'

const Section = styled.section`
  width: 100%;
  display: grid;
  place-items: center;
  text-align: center;

  ${media.mobile} {
    grid-row-gap: 40px;
    margin: 36px auto 58px auto;
  }
  ${media.tablet} {
    grid-row-gap: 60px;
    margin: 110px auto 120px auto;
    min-height: 277px;
  }
`

const ProductsContainer = styled('div')<{ cols: number }>`
  display: grid;
  place-items: center;
  padding: 0 10px;

  ${media.mobile} {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px 12px;

    & > h2 {
      font-size: 34px;
      grid-column: span 2;
      margin: 0;
      padding: 0 30px 0 10px;
    }
  }

  ${media.tablet} {
    grid-template-columns: repeat(${props => props.cols}, 170px);

    width: fit-content;

    margin: 0 auto;

    & > h2 {
      font-size: 30px;
      grid-column: span 1;
      margin-bottom: 70px;
    }
  }
`

const CardWrapper = styled('div')<{ spanCols: boolean }>`
  width: 100%;

  ${media.mobile} {
    grid-column: span ${props => (props.spanCols ? 2 : 1)};
  }
  ${media.tablet} {
    grid-column: span 1;
  }
`

export default ({ currentSku }: { currentSku: string }) => {
  const { products: productMap } = useContext(StoreContext)

  const skus = Object.keys(productMap.byProduct).filter((sku: string) => {
    const differentSku = sku !== currentSku
    const { jsonData } = productMap.byProduct[sku]
    return differentSku && (!jsonData || !jsonData.bundle)
  })

  const products = skus
    .filter(sku => sku !== 'SPIRULINA' && !sku.includes('BUNDLE'))
    .map(sku => productMap.byProduct[sku])

  const oddNumberOfOptions = products.length % 3 === 0
  const lastIndexOfOptions = products.length - 1

  return (
    <Section>
      <ProductsContainer cols={products.length + 1}>
        <H2>{`Other\ndelicious\nGrummies`}</H2>
        {products.map((product, idx) => {
          const spanCols = oddNumberOfOptions && idx === lastIndexOfOptions
          return product.jsonData ? (
            <CardWrapper spanCols={spanCols} key={product.jsonData.sku}>
              <SmallProductCard product={product} />
            </CardWrapper>
          ) : null
        })}
      </ProductsContainer>
    </Section>
  )
}

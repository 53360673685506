import React from 'react'
import styled from 'styled-components'

const ColoredPath = styled('path')<{ fillColor: string }>`
  fill: ${props => props.theme.colors[props.fillColor]};
`

export default ({ fill }: { fill: string }) => (
  <svg
    width="152"
    height="593"
    viewBox="0 0 152 593"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ColoredPath
      d="M-243.961 88.719C-152.086 54.7297 -70.3481 97.4912 -22.804 150.663C18.4907 196.858 91.2828 298.653 81.5399 376.76C71.7971 454.867 -16.418 509.93 -135.349 517.429C-180.484 518.078 -312.542 521.132 -402.455 397.086C-476.201 291.342 -461.034 242.635 -446.612 219.259C-432.191 195.883 -333.693 121.897 -243.961 88.719Z"
      fillColor={fill}
    />
  </svg>
)

import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { gsap } from 'gsap'

import { H2 } from '../shared/text'
import { PdpButton } from '../shared/buttons'
import { media } from '../../styles/util'

import TextHeader from '../../assets/img/text-headers/happiness-guarantee.svg'

const GradientWrapper = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  background: linear-gradient(
    180deg,
    rgba(250, 102, 102, 0) 19.76%,
    rgba(250, 102, 102, 0.19) 40.32%,
    rgba(164, 87, 138, 0.13) 54.55%,
    rgba(164, 87, 138, 0) 81.33%
  );

  ${media.mobile} {
    padding: 85px 20px 60px 20px;
  }
  ${media.desktop} {
    padding: 120px 20px 60px 20px;
  }
`

const Container = styled.div`
  position: relative;

  width: 100%;
  max-width: 688px;
  display: grid;
  place-items: center;
  grid-row-gap: 50px;
  text-align: center;

  ${media.mobile} {
    & button h2 {
      font-size: 18px;
    }
  }
  ${media.tablet} {
    & button h2 {
      font-size: 25px;
    }
    & h2 {
      font-size: 30px;
      line-height: 42px;
    }
  }
`

const TextHeaderContainer = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  padding: 30px 0 0 0;
`

const BlobBaseStyling = styled.div`
  position: absolute;
  height: auto;
  z-index: 2;
`

const BlueGreenBlobImg = styled(BlobBaseStyling)`
  width: 53px;

  ${media.mobile} {
    bottom: -14px;
    right: 5%;
  }
  ${media.tablet} {
    bottom: 10px;
    right: 20%;
  }
`

const PurpleBlobImg = styled(BlobBaseStyling)`
  width: 45px;
  top: 0;
  right: 38%;
`

const YellowBlobImg = styled(BlobBaseStyling)`
  width: 25px;
  top: -3px;
  right: 36%;
`

const BlueBlobImg = styled(BlobBaseStyling)`
  width: 37px;

  ${media.mobile} {
    bottom: -6px;
    left: 17%;
  }
  ${media.tablet} {
    bottom: 10px;
    left: 27%;
  }
`

const Capitalized = styled.span`
  text-transform: capitalize;
`

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const BadgeImgWrapper = styled.div`
  width: 112px;
  height: 112px;
  position: absolute;
  left: -112px;
  top: 10%;
  transform: translateY(-50%);
  animation: ${rotateAnimation} 20s linear infinite;

  ${media.mobile} {
    display: none;
  }
  ${media.desktop} {
    display: block;
  }
`

const query = graphql`
  query {
    blueGreenBlob: file(relativePath: { regex: "/whats-inside/blue-green-blob-round.png/" }) {
      childImageSharp {
        fluid(maxWidth: 140) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    purpleBlob: file(relativePath: { regex: "/whats-inside/purple-blob-round.png/" }) {
      childImageSharp {
        fluid(maxWidth: 62) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    yellowBlob: file(relativePath: { regex: "/whats-inside/yellow-blob-round.png/" }) {
      childImageSharp {
        fluid(maxWidth: 35) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    blueBlob: file(relativePath: { regex: "/whats-inside/blue-blob-round.png/" }) {
      childImageSharp {
        fluid(maxWidth: 45) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    badge: file(relativePath: { regex: "/icons/30-day-badge.png/" }) {
      childImageSharp {
        fluid(maxWidth: 110) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

const HappinessGuarantee = ({
  productName,
  isBundle,
}: {
  productName: string
  isBundle: boolean
}) => {
  const data = useStaticQuery(query)
  const { blueGreenBlob, purpleBlob, yellowBlob, blueBlob, badge } = data

  useEffect(() => {
    gsap.to('.pdp__happiness-guarantee__blob', {
      duration: 'random(4, 8)',
      stagger: 0.25,
      yoyo: true,
      repeat: -1,
      ease: 'ease-in-out',
      y: 'random(12, 16)',
    })
  }, [])

  const scrollToTop = () => {
    if (typeof window !== undefined) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }

  return (
    <GradientWrapper>
      <Container>
        <BadgeImgWrapper>
          <Img fluid={badge.childImageSharp.fluid} alt="30 days money back guarantee" />
        </BadgeImgWrapper>
        <TextHeaderContainer>
          <img src={TextHeader} alt="Happiness guarantee badge" />
          <BlueGreenBlobImg className="pdp__happiness-guarantee__blob">
            <Img fluid={blueGreenBlob.childImageSharp.fluid} aria-hidden="true" />
          </BlueGreenBlobImg>
          <PurpleBlobImg className="pdp__happiness-guarantee__blob">
            <Img fluid={purpleBlob.childImageSharp.fluid} aria-hidden="true" />
          </PurpleBlobImg>
          <YellowBlobImg className="pdp__happiness-guarantee__blob">
            <Img fluid={yellowBlob.childImageSharp.fluid} aria-hidden="true" />
          </YellowBlobImg>
          <BlueBlobImg className="pdp__happiness-guarantee__blob">
            <Img fluid={blueBlob.childImageSharp.fluid} aria-hidden="true" />
          </BlueBlobImg>
        </TextHeaderContainer>
        <H2>
          We stand by our real ingredients and delicious taste. Get a full refund within 30 days if
          you don’t love your Grummies.
        </H2>
        <PdpButton onClick={scrollToTop}>
          <H2>
            Get {isBundle && 'the  '}
            <Capitalized>{productName}</Capitalized> now
          </H2>
        </PdpButton>
      </Container>
    </GradientWrapper>
  )
}

export default HappinessGuarantee

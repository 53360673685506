import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Modal from '@material-ui/core/Modal'

import { P } from '../../shared/text'
import { media } from '../../../styles/util'

import InfoIcon from '../../../assets/img/icons/information-icon.svg'

const AfterpayContainer = styled.div`
  height: 66px;
  white-space: pre-line;

  display: grid;
  place-items: center;

  ${media.mobile} {
    text-align: center;
    margin: 0 auto;
  }
  ${media.desktop} {
    width: 220px;
    justify-content: start;
    margin: 0 0 0 12px;
  }

  & > * {
    display: inline-block;
    vertical-align: middle;
  }
`

const InlineBlockSpan = styled.span`
  display: inline-block;
  vertical-align: middle;
`

const AfterpayLogoWrapper = styled.span`
  display: grid;
  grid-template-columns: 94px 18px;
  grid-column-gap: 7px;
  vertical-align: middle;
  margin: 0 0 0 6px;
`

const InfoIconImg = styled.span`
  cursor: pointer;
`

const StyledP = styled(P)`
  letter-spacing: 0.5px;

  ${media.mobile} {
    font-size: 17px;
  }
  ${media.desktop} {
    font-size: 16px;
  }
`

const DesktopImgWrapper = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${media.mobile} {
    display: none;
  }
  ${media.tablet} {
    display: block;
    width: 60vw;
    max-width: 700px;
  }
`

const MobileImgWrapper = styled(DesktopImgWrapper)`
  ${media.mobile} {
    display: block;
    height: 100vh;
    width: 100%;
  }
  ${media.tablet} {
    display: none;
  }
`

const query = graphql`
  query {
    desktop: file(relativePath: { regex: "/afterpay/desktop-modal.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    mobile: file(relativePath: { regex: "/afterpay/mobile-modal.png/" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
interface FluidInterface {
  srcSet: string
  src: string
  sizes: string
  aspectRatio: number
}

const Afterpay = ({ price, fluid }: { price: string; fluid: FluidInterface }) => {
  const data = useStaticQuery(query)

  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <AfterpayContainer>
        <StyledP>
          <b>{`or 4 payments of $${price}\nwith`}</b>
          <InlineBlockSpan>
            <AfterpayLogoWrapper>
              <Img
                fluid={fluid}
                alt="Afterpay logo"
                style={{ height: '100%', width: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
                Tag="span"
              />
              <InfoIconImg>
                <img src={InfoIcon} alt="More information" onClick={() => setModalOpen(true)} />
              </InfoIconImg>
            </AfterpayLogoWrapper>
          </InlineBlockSpan>
        </StyledP>
      </AfterpayContainer>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onClick={() => setModalOpen(false)}
        aria-labelledby="Afterpay infographic"
      >
        <>
          <DesktopImgWrapper>
            <Img
              fluid={data.desktop.childImageSharp.fluid}
              alt="Afterpay infographic"
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          </DesktopImgWrapper>

          <MobileImgWrapper>
            <Img
              fluid={data.mobile.childImageSharp.fluid}
              alt="Afterpay infographic"
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          </MobileImgWrapper>
        </>
      </Modal>
    </>
  )
}

export default Afterpay

import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Link, navigate } from 'gatsby'
import Img from 'gatsby-image'

import { FilledButton } from './buttons'
import { H2 } from './text'
import { media } from '../../styles/util'

const wiggle = keyframes`
  0% { transform: rotate(0deg); }
  30% { transform: rotate(2deg) scale(1.02); }
  70% { transform: rotate(-2deg) scale(1.02); }
  100% { transform: rotate(0deg); }
`

const Card = styled('div')`
  width: 100%;
  margin: 0 auto;
  display: grid;
  place-items: center;

  text-align: center;
  grid-row-gap: 10px;

  ${media.mobile} {
    grid-template-rows: 196px 30px auto;
  }
  ${media.tablet} {
    grid-template-rows: 150px 30px auto;
  }
  ${media.desktop} {
    grid-template-rows: auto 37px auto;
  }

  & a {
    display: grid;
    place-items: center;
  }

  & h2 {
    font-size: 16px;
    white-space: pre;
    line-height: 1.3;
  }

  & img:hover {
    animation: ${wiggle} 250ms;
  }
`

interface ProductDetailInterface {
  productKey: string
  quantityKey: string
  price: number
  variantId: string
}

interface ProductGroupInterface {
  [key: string]: ProductDetailInterface
}

interface JsonNodeInterface {
  images: any
  sku: string
  description: string
  slug: string
  title: string
  shortTitle: string
}

interface ProductInterface {
  subscription: ProductGroupInterface
  oneTime: ProductGroupInterface
  jsonData: JsonNodeInterface
}

export default ({ product }: { product: ProductInterface }) => {
  const { title, shortTitle, slug, images } = product.jsonData
  const image = images.bottles.single.childImageSharp.fluid

  return (
    <Card>
      <Link to={`/${slug}`} style={{ width: '100%', height: '100%' }} aria-label="Product detail">
        <Img
          fluid={image}
          alt={title}
          style={{ width: '100%', height: '100%', maxWidth: 145 }}
          imgStyle={{ objectFit: 'scale-down' }}
        />
      </Link>
      <Link to={`/${slug}`} aria-label="Product detail">
        <H2>{shortTitle}</H2>
      </Link>
      <FilledButton onClick={() => navigate(`/${slug}`)}>Shop Now</FilledButton>
    </Card>
  )
}

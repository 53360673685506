import React from 'react'
import styled from 'styled-components'

const FilterTagContainer = styled.div`
  border-radius: 10px;
  color: white;
  background-color: ${props => props.theme.colors.purple};
  font-size: 12px;
  line-height: 1;
  margin: 0 0 6px 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: fit-content;
  font-family: Beacon-Medium, sans-serif;
  padding: 4px 11px 5px 11px;

  & span {
    margin-left: 6px;
  }
`
interface FilterTagProps {
  clickHandler: (args?: any) => void
  text: string
  id: string
}

const FilterTag: React.FC<FilterTagProps> = ({ text, id, clickHandler }) => {
  const toggle = () => {
    clickHandler(id)
  }

  return (
    <FilterTagContainer onClick={toggle}>
      {text} <span>✕</span>
    </FilterTagContainer>
  )
}
export default FilterTag

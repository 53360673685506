import React, { useEffect } from 'react'
import styled from 'styled-components'
import { gsap } from 'gsap'

import OptionRow from './OptionRow'
import { H2, P } from '../../../shared/text'
import { media } from '../../../../styles/util'

const OptionsGrid = styled.div`
  display: grid;
  visibility: hidden;
  grid-row-gap: 10px;

  ${media.desktop} {
    grid-row-gap: 20px;
    justify-items: start;
    margin: 25px 0 0 0;
    grid-row-gap: 9px;
  }
`

const ChoosePackageText = styled(H2)`
  margin-bottom: 5px;
`

const ButtonGroup = styled.div`
  display: grid;
  align-items: center;
  grid-row-gap: 12px;

  ${media.mobile} {
    justify-content: center;
  }
  ${media.desktop} {
    justify-content: start;
  }
`

const ButtonGroupHeader = styled(P)`
  font-family: Beacon-Bold, sans-serif;
  line-height: 1;
  text-align: center;
  margin-top: 10px;

  ${media.desktop} {
    // Prevents bottom header from expanding right past button
    max-width: 264px;
  }
`

interface ProductSelectorInterface {
  otpOptions: string[]
  productDetail: any
  revealPricing: boolean
  productType: string
  setProductType: (type: string) => void
  bottleImage: any
  productTitle: string
  textColor: string
  oneTime: boolean
  setOneTime: (arg: boolean) => void
  smartrrAvailable: boolean
}

const ProductSelector = (props: ProductSelectorInterface) => {
  const {
    otpOptions,
    productDetail,
    revealPricing,
    productType,
    setProductType,
    bottleImage,
    productTitle,
    textColor,
    oneTime,
    setOneTime,
    smartrrAvailable,
  } = props

  const optionCount = otpOptions.length

  useEffect(() => {
    if (revealPricing) {
      const tl = gsap.timeline()
      tl.to('.pdp__options-grid', { autoAlpha: 1 })
      tl.from('.pdp__checkout-option-row', {
        opacity: 0,
        y: 10,
        stagger: 0.1,
        duration: 1.5,
        ease: 'elastic',
      })
    }
  }, [revealPricing, optionCount])

  return optionCount > 1 ? (
    <OptionsGrid className="pdp__options-grid">
      <ChoosePackageText>Choose your package:</ChoosePackageText>

      {smartrrAvailable && (
        <ButtonGroup>
          <ButtonGroupHeader className="pdp__checkout-option-row">
            Subscribe & save
          </ButtonGroupHeader>
          <OptionRow
            type="single"
            isSubscription
            productDetail={productDetail}
            productType={productType}
            setProductType={setProductType}
            bottleImage={bottleImage}
            productTitle={productTitle}
            textColor={textColor}
            oneTime={oneTime}
            setOneTime={setOneTime}
          />
        </ButtonGroup>
      )}

      <ButtonGroup>
        <ButtonGroupHeader className="pdp__checkout-option-row">
          One-time purchase
        </ButtonGroupHeader>
        {otpOptions.map((type: string) => (
          <OptionRow
            key={type}
            type={type}
            productDetail={productDetail}
            productType={productType}
            setProductType={setProductType}
            bottleImage={bottleImage}
            productTitle={productTitle}
            textColor={textColor}
            oneTime={oneTime}
            setOneTime={setOneTime}
          />
        ))}
      </ButtonGroup>
    </OptionsGrid>
  ) : null
}

export default ProductSelector

import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { H3 } from '../../shared/text'
import { media } from '../../../styles/util'

import PurpleStar from '../../../assets/img/icons/purple-star.svg'

const FlexRow = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  margin: 9px auto 0 auto;

  ${media.tablet} {
    & button {
      margin: 10px 0 20px 90px;
    }
  }

  ${media.desktop} {
    margin: 6px 0 0 0;
    & button {
      margin: 0 0 0 0;
    }
  }
`

const StarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 16px);
  grid-column-gap: 6px;
  margin-right: 12px;
`

interface ReviewStats {
  totalReviewCount: number
  averageRating: number
}

const Reviews = ({ reviewStats }: { reviewStats: ReviewStats }) => {
  let reviewCount = reviewStats.totalReviewCount
  reviewCount = 0

  return reviewCount > 0 ? (
    <FlexRow style={{ marginBottom: 16 }}>
      <StarGrid>
        <img src={PurpleStar} alt="Star icon" />
        <img src={PurpleStar} alt="Star icon" />
        <img src={PurpleStar} alt="Star icon" />
        <img src={PurpleStar} alt="Star icon" />
        <img src={PurpleStar} alt="Star icon" />
      </StarGrid>
      <H3>
        {reviewCount} reviews <Link to="#reviews">See More</Link>
      </H3>
    </FlexRow>
  ) : null
}

export default Reviews

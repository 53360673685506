import React from 'react'
import styled from 'styled-components'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import { BasicButton } from './buttons'
import { media } from '../../styles/util'

const Container = styled.div`
  width: fit-content;
  min-width: 170px;

  ${media.mobile} {
    margin: 0 12px;
  }
  ${media.tablet} {
    margin: 0;
  }

  & button {
    width: fit-content;
    min-width: 160px;
    padding-right: 6px;
  }
`

const Flexbox = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.purple} !important;

  &:hover {
    color: white !important;
  }

  & svg {
    margin-left: 3px;
    margin-bottom: -1px;
  }
`
interface DropdownMenuInterface {
  label: string
  options: { label: string; value: string }[]
  filterProduct: (product: string) => void
  reviewFilters: { [key: string]: string }
}

const DropdownMenu = ({ label, options, filterProduct, reviewFilters }: DropdownMenuInterface) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const activeFilters = Object.keys(reviewFilters)

  return (
    <Container>
      <BasicButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <Flexbox>
          {label}
          <KeyboardArrowDownIcon />
        </Flexbox>
      </BasicButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {options.map(option => (
          <MenuItem
            selected={activeFilters.includes(option.value)}
            onClick={() => {
              filterProduct(option.value)
              handleClose()
            }}
            key={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Container>
  )
}

export default DropdownMenu

import React, { useState } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

import BlobTopLeft from 'src/components/shared/icons/nutrition/blob-top-left'
import BlobTopRight from 'src/components/shared/icons/nutrition/blob-top-right'
import BlobBottomLeft from 'src/components/shared/icons/nutrition/blob-bottom-left'
import BlobBottomRight from 'src/components/shared/icons/nutrition/blob-bottom-right'
import { H1, H2, P } from 'src/components/shared/text'

import TurmericBackgroundDesktop from 'src/assets/img/pdp/backgrounds/turmeric-desktop.svg'
import AshwagandhaBackgroundDesktop from 'src/assets/img/pdp/backgrounds/ashwagandha-desktop.svg'
import AcvBackgroundDesktop from 'src/assets/img/pdp/backgrounds/acv-desktop.svg'
import ElderberryBackgroundDesktop from 'src/assets/img/pdp/backgrounds/elderberry-desktop.svg'
import SpirulinaBackgroundDesktop from 'src/assets/img/pdp/backgrounds/spirulina-desktop.svg'

import TurmericBackgroundMobile from 'src/assets/img/pdp/backgrounds/turmeric-mobile.svg'
import AshwagandhaBackgroundMobile from 'src/assets/img/pdp/backgrounds/ashwagandha-mobile.svg'
import AcvBackgroundMobile from 'src/assets/img/pdp/backgrounds/acv-mobile.svg'
import ElderberryBackgroundMobile from 'src/assets/img/pdp/backgrounds/elderberry-mobile.svg'
import SpirulinaBackgroundMobile from 'src/assets/img/pdp/backgrounds/spirulina-mobile.svg'

import BenefitAccordion from './BenefitAccordion'

import { media } from '../../../styles/util'

const productImageMap: { desktop: { [key: string]: string }; mobile: { [key: string]: string } } = {
  desktop: {
    TURMERIC: TurmericBackgroundDesktop,
    ASHWAGANDHA: AshwagandhaBackgroundDesktop,
    ACV: AcvBackgroundDesktop,
    ELDERBERRY: ElderberryBackgroundDesktop,
    SPIRULINA: SpirulinaBackgroundDesktop,
  },
  mobile: {
    TURMERIC: TurmericBackgroundMobile,
    ASHWAGANDHA: AshwagandhaBackgroundMobile,
    ACV: AcvBackgroundMobile,
    ELDERBERRY: ElderberryBackgroundMobile,
    SPIRULINA: SpirulinaBackgroundMobile,
  },
}

const Section = styled('div')<{ sku: string }>`
  position: relative;
  overflow: hidden;
  width: 100%;

  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;

  display: grid;
  justify-content: center;

  color: white;

  ${media.mobile} {
    background-image: url(${props => productImageMap.mobile[props.sku]});
    padding: 80px 0 0 0;
    margin-bottom: 40px;
  }
  ${media.tablet} {
    background-image: url(${props => productImageMap.desktop[props.sku]});
    padding: 100px 0 0 0;
    margin-bottom: 80px;
  }
`

const BaseBlob = styled.div`
  position: absolute;
  z-index: 0;

  ${media.mobile} {
    display: none;
  }
  ${media.tablet} {
    display: block;
  }
`

const BlobTopLeftImage = styled(BaseBlob)`
  width: 120px;
  top: -30px;
  left: 0;
`

const BlobTopRightImage = styled(BaseBlob)`
  width: 140px;
  top: 0px;
  right: 0;
`

const BlobBottomLeftImage = styled(BaseBlob)`
  width: 120px;
  bottom: -6px;
  left: 15vw;
`

const BlobBottomRightImage = styled(BaseBlob)`
  width: 200px;
  bottom: -6px;
  right: 0;
`

const GridWrapper = styled('div')<{ backgroundColor: string }>`
  width: 100vw;
  height: fit-content;

  background-color: ${props => props.theme.colors[props.backgroundColor]};

  display: grid;
  place-items: center;

  padding: 0 20px 62px 20px;

  ${media.tablet} {
    padding: 0 40px 74px 40px;
  }
`

const Grid = styled.div`
  width: 100%;

  display: grid;
  place-items: center;
  grid-gap: 41px;

  margin-top: 48px;

  ${media.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${media.desktop} {
    max-width: 1150px;
    grid-template-columns: repeat(3, 1fr);
  }
`

const BaseCard = styled('div')<{ backgroundColor: string }>`
  position: relative;

  // background-color: ${props => props.theme.colors[props.backgroundColor]};

  width: 100%;
  max-width: 350px;
  height: 550px;

  border: 2.5px solid white;
  border-radius: 15px;

  z-index: 5;
`

const LeftCard = styled(BaseCard)`
  display: grid;
  grid-template-rows: 1fr 1fr;
`

const LeftCardImageWrapper = styled.div`
  position: relative;
  z-index: 4;

  height: 100%;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  padding-top: 0;

  display: grid;
  place-items: center;
`

const LeftCardTextBox = styled(P)`
  white-space: pre-line;
  height: fit-content;

  border-top: 2.5px solid white;

  font-size: 16px;
  line-height: 24px;
  text-align: left;

  padding: 29px 18px 25px 18px;
`

const OneLiner = styled.span`
  font-family: Beacon-Bold, sans-serif;
  padding-bottom: 10px;
  font-size: 18px;
  line-height: 22px;
  display: block;
`

const MiddleCard = styled(BaseCard)`
  display: grid;
  grid-template-rows: 63px auto 1fr;
  grid-row-gap: 14px;
`

const CardHeader = styled('div')<{ textColor: string }>`
  position: relative;

  width: 101%;
  margin: -2px 0 0 -2px; // Offsets weird gap
  height: 100%;

  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  display: grid;
  place-items: center;

  background-color: white;
  color: ${props => props.theme.colors[props.textColor]};
`

const SupportsText = styled(P)`
  font-family: Beacon-Bold, sans-serif;
  font-size: 14px;
  line-height: 1;
`

const BenefitsGummyImage = styled.div`
  width: 49px;

  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
  right: 20px;
`

const AccordionGrid = styled.div`
  width: 100%;
  padding: 0 31px;
  display: grid;
  grid-auto-rows: min-content;
  grid-row-gap: 22px;
`

const RightCard = styled(BaseCard)`
  display: grid;
  grid-template-rows: 63px 1fr;
  grid-row-gap: 14px;
`

const SupplementFactsImage = styled.div`
  align-self: start;

  width: 100%;
  height: auto;
  max-height: 425px;

  margin: 0 auto;
  padding: 10px 20px;
`

interface BenefitInterface {
  label: string
  labelImg?: string
  detail: string
  frontImg: {
    childImageSharp: {
      fluid: any
    }
  }
  backImg: {
    childImageSharp: {
      fluid: any
    }
  }
}

interface BadgeInterface {
  name: string
  overrideLabel?: string
}

interface BlobsInterface {
  img: {
    childImageSharp: {
      fluid: any
    }
  }
  blobCss: string
  mobileBlobCss: string
}

interface DataInterface {
  sku: string
  bundle: boolean
  gradient: string
  color: string
  colorSecondary: string
  slug: string
  title: string
  shortTitle: string
  description: string
  oneLiner: string
  ingredientFact: string
  images: any
  keyBenefits: BenefitInterface[]
  pdpBlobs: BlobsInterface[]
  superfood: string
  certifications: BadgeInterface[]
  childProducts: string[]
}

const RealNutrition = ({ data, title }: { data: DataInterface; title: string }) => {
  const [expandedBenefit, setExpandedBenefit] = useState(1)

  return (
    <Section sku={data.sku}>
      <BlobTopLeftImage>
        <BlobTopLeft fill={data.colorSecondary} />
      </BlobTopLeftImage>
      <BlobTopRightImage>
        <BlobTopRight fill={data.colorSecondary} />
      </BlobTopRightImage>
      <BlobBottomLeftImage>
        <BlobBottomLeft fill={data.colorSecondary} />
      </BlobBottomLeftImage>
      <BlobBottomRightImage>
        <BlobBottomRight fill={data.colorSecondary} />
      </BlobBottomRightImage>

      <H1>{title}</H1>

      <GridWrapper backgroundColor={data.color}>
        <Grid>
          <LeftCard backgroundColor={data.color}>
            <LeftCardImageWrapper>
              <Img
                fluid={data.images.cardImg.childImageSharp.fluid}
                alt="Bottle image"
                style={{
                  height: 'auto',
                  width: '130%',
                  transform: 'translate(-50%, -50%)',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                }}
              />
            </LeftCardImageWrapper>
            <LeftCardTextBox>
              <OneLiner>{data.superfood[0]}</OneLiner>
              <span>{data.superfood[1]}</span>
            </LeftCardTextBox>
          </LeftCard>
          <MiddleCard backgroundColor={data.color}>
            <CardHeader textColor={data.color}>
              <H2>Key Benefits</H2>
              <BenefitsGummyImage>
                <Img
                  fluid={data.images.benefitsGummy.childImageSharp.fluid}
                  alt="Gummy image"
                  style={{ height: '100%', width: '100%' }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </BenefitsGummyImage>
            </CardHeader>
            <SupportsText>Supports:</SupportsText>
            <AccordionGrid>
              {data.keyBenefits.map((benefit, idx) => (
                <BenefitAccordion
                  key={benefit.label}
                  label={benefit.label}
                  detail={benefit.detail}
                  textColor={data.color}
                  idx={idx + 1}
                  expandedBenefit={expandedBenefit}
                  setExpandedBenefit={setExpandedBenefit}
                />
              ))}
            </AccordionGrid>
          </MiddleCard>
          <RightCard backgroundColor={data.color}>
            <CardHeader textColor={data.color}>
              <H2>Supplement Facts</H2>
            </CardHeader>
            <SupplementFactsImage>
              <Img
                fluid={data.images.supplementFacts.childImageSharp.fluid}
                aria-hidden
                style={{ height: '100%', width: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
              />
            </SupplementFactsImage>
          </RightCard>
        </Grid>
      </GridWrapper>
    </Section>
  )
}

export default RealNutrition

import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

import { H2, P } from '../../../shared/text'
import { media } from '../../../../styles/util'

const Container = styled.div`
  width: 100%;
  display: grid;
  align-items: center;

  ${media.mobile} {
    grid-column-gap: 9px;
    grid-template-columns: 0 1fr 0;
  }
  ${media.tablet} {
    grid-template-columns: 1fr auto 1fr;
  }
  ${media.desktop} {
    grid-column-gap: 0;
    grid-template-columns: 0 auto 1fr;
  }
`

const ImageGrid = styled.div`
  color: ${props => props.theme.colors.orange};
  font-size: 14px;

  ${media.mobile} {
    display: none;
  }

  ${media.desktop} {
    display: grid;
    align-items: center;

    grid-template-columns: 18px 1fr;
    grid-column-gap: 5px;
    text-align: left;
    margin-left: 13px;

    & > div img {
      width: 18px;
    }
  }
`

const OptionButtonGradientWrapper = styled('div')<{ active: boolean }>`
  border-radius: 15px;
  background: ${props =>
    props.active
      ? 'linear-gradient(90deg, #fa6666 1.9%, #f090c3 28.98%, #52d9bf 64.74%, #21a0f8 100%)'
      : 'transparent'};

  padding: 4px;

  ${media.mobile} {
    width: 300px;
  }
  ${media.tablet} {
    width: 280px;
  }
`

const OptionButton = styled('div')<{ active: boolean }>`
  background-color: white;
  cursor: ${props => (!props.active ? 'pointer' : 'default')};
  border: 2px solid ${props => (props.active ? 'white' : props.theme.colors.purple)};
  border-radius: 12px;
  padding: 9px 20px 10px 20px;

  display: grid;
  place-items: center;

  &:hover {
    border-width: 3px;
    padding: 8px 20px 9px 20px;
  }

  & h2 {
    line-height: 1;
  }

  ${media.mobile} {
    height: 70px;
  }
  ${media.desktop} {
    height: fit-content;
  }
`

const ComparablePrice = styled.span`
  color: ${props => props.theme.colors.lightPurple};
  text-decoration: line-through;
  margin-left: 3px;
`

const HideMobileSpan = styled.span`
  ${media.mobile} {
    display: none;
  }
  ${media.desktop} {
    display: inline-block;
    margin-right: 4px;
  }
`

const BottlePrice = styled(P)<{ textColor: string }>`
  font-family: Beacon-Bold, sans-serif;
  color: ${props => props.theme.colors[props.textColor]};
  font-size: 16px;
`

const MobileButtonDetails = styled(P)<{ active: boolean }>`
  font-family: Beacon-Bold, sans-serif;
  font-size: 14px;
  line-height: 1;
  margin-top: 6px;

  background: ${props =>
    !props.active
      ? props.theme.colors.lightPurple
      : 'linear-gradient(99.33deg, #fa6666 13.56%, #f090c3 38.67%, #52d9bf 45.34%, #21a0f8 68.76%)'};

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${media.desktop} {
    display: none;
  }
`

interface OptionRowInterface {
  productDetail: any
  productType: string
  setProductType: (type: string) => void
  bottleImage: any
  productTitle: string
  textColor: string
  type: string
  isSubscription?: boolean
  oneTime: boolean
  setOneTime: (oveTime: boolean) => void
}

const OptionRow = ({
  productDetail,
  productType,
  setProductType,
  bottleImage,
  productTitle,
  textColor,
  type,
  isSubscription = false,
  oneTime,
  setOneTime,
}: OptionRowInterface) => {
  if (!productDetail) return null
  let displayType = '1'
  if (type.includes('multipack')) displayType = type.split('_')[1]

  const isBundle = productDetail.jsonData.bundle
  const {
    compareAt: { price: compareAtPrice },
    oneTime: { price: otpPrice },
  } = productDetail[type].pricing

  // Bundle discounts are spoofed to 15% since in config it is 5.55% off to get to a $34 price
  const subDiscount = isBundle
    ? 15
    : productDetail[type].pricing.subscription?.percentageDiscount * 100

  const subPrice = productDetail[type].pricing.subscription?.price
  const pricePerBottle = otpPrice / (isBundle ? 2 * Number(displayType) : Number(displayType))
  const displayedPrice = isSubscription && subPrice ? subPrice : otpPrice
  const formattedPrice = displayedPrice.toFixed(0)

  const displayedComparable = !isBundle && type === 'single' ? otpPrice : compareAtPrice

  const handleClick = () => {
    setOneTime(!isSubscription)
  }

  const isActiveProductType = productType === type
  const isActive = isActiveProductType && isSubscription !== oneTime

  return (
    <Container key={type} className="pdp__checkout-option-row">
      <div />

      <OptionButtonGradientWrapper active={isActive} onClick={handleClick}>
        <OptionButton onClick={() => setProductType(type)} active={isActive}>
          <H2>
            {displayType} pack - ${formattedPrice}{' '}
            {displayedComparable > 0 && (
              <ComparablePrice>${displayedComparable.toFixed(0)}</ComparablePrice>
            )}
          </H2>
          <MobileButtonDetails active={isActive}>
            {isSubscription
              ? `Save ${subDiscount}% + cancel anytime`
              : `Only $${pricePerBottle.toFixed(0)} per bottle`}
          </MobileButtonDetails>
        </OptionButton>
      </OptionButtonGradientWrapper>

      <ImageGrid>
        <Img
          fluid={bottleImage}
          alt={`${productTitle} bottle`}
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ objectFit: 'contain' }}
        />
        {isSubscription ? (
          <BottlePrice textColor={textColor}>
            <HideMobileSpan>= </HideMobileSpan>Save {subDiscount.toFixed(0)}% monthly
          </BottlePrice>
        ) : (
          <BottlePrice textColor={textColor}>
            <HideMobileSpan>= </HideMobileSpan>Only ${pricePerBottle.toFixed(0)} per bottle
          </BottlePrice>
        )}
      </ImageGrid>
    </Container>
  )
}

export default OptionRow
